/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import { Global } from "@emotion/core"

import { getItemList } from "../utils/sidebar/item-list"
import StickyResponsiveSidebar from "./sidebar/sticky-responsive-sidebar"
import { HeaderHeight, SidebarWidth } from "./shared/constants/layout"

export default function PageWithSidebar({
  children,
  enableScrollSync,
  location,
  SidebarComponent,
}) {
  const itemList = getItemList(location.pathname)
  const Sidebar = SidebarComponent || StickyResponsiveSidebar

  // Make room for the `sidebar` if there is an list of sidebar items to display
  const leftPadding = itemList
    ? [null, null, null, SidebarWidth.default, SidebarWidth.large]
    : null

  return (
    <Fragment>
      <Global
        // ensure anchor link targets are not covered by <Header isFixed>
        // https://caniuse.com/?search=scroll-padding-top
        //
        // - also add a bit of extra whitespace via calc
        // - breakpoint should match
        //   - components/PageHeader/ `isFixed`
        //     (where we use a different theme, mediaQueries.tablet luckily matches `md`)
        //     (^ see https://gatsby-interface.netlify.app/?path=/docs/theme-media-queries--page)
        //   - components/sidebar/sidebar (where we use the old gatsbyjs.org theme tokens, like here)
        // to make the ToC's `position:sticky` work, we need to reset the global
        // `overflow: hidden` on body set in utils/typography.js
        styles={theme => ({
          [theme.mediaQueries.tablet]: {
            html: { scrollPaddingTop: `calc(${HeaderHeight} + 1.5rem)` },
            body: { overflow: "visible" },
          },
        })}
      />
      {/*  
      // push down content to account for the space that <PageHeader isFixed> will use
      */}
      <div
        sx={{
          pl: leftPadding,
          pt: [null, null, null, HeaderHeight],
        }}
      >
        {children}
      </div>
      {/* Display the sidebar only if there is a list of items to display */}
      {itemList ? (
        <Sidebar
          enableScrollSync={enableScrollSync}
          itemList={itemList.items}
          title={itemList.title}
          disableExpandAll={itemList.disableExpandAll}
          disableAccordions={itemList.disableAccordions}
          key={location.pathname}
          location={location}
        />
      ) : null}
    </Fragment>
  )
}
